import { Component, OnInit } from '@angular/core';
import { HttpService } from './../services/Http/http.service'
import {environment} from './../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router';
import { RolesService } from './../services/globals/roles.service'
import { AlertService } from 'ngx-alerts';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
  	private alertService: AlertService, 
  	private serviceHttp: HttpService, 
  	private router: Router,
  	private role: RolesService ) { }

  name: string;
  password: string;
  options: any = {
    text:"Iniciar sesión",
    disabled: false,
    type:"btn btn-primary",
    icon:"fa fa-spinner fa-spin",
    isLoading:false
  };

  ngOnInit() {
    console.log(environment.url_api+"/login");
  }

  loginMethod(event:any){
   if(event.keyCode == 13){
      this.login();
   }else{
   }
  }

  loginClick(){
    this.login();
  }

  login():void{
      let url_login="/login";
      let parameters = {nombre:this.name,password:this.password};

  
        this.options.disabled=true;
        this.options.isLoading=true;
        this.serviceHttp.postHttp(url_login,parameters,this.options).
        subscribe(a => {
        	this.role.set(a.user.id_rol);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("permissions");
            localStorage.removeItem("grupo");
            localStorage.setItem("token",a.token);
            localStorage.setItem("user",JSON.stringify(a.user));
            localStorage.setItem("permissions",JSON.stringify(a.permissions));
            localStorage.setItem("grupo",JSON.stringify(a.grupo));
            this.router.navigate(["/dashboard",{ outlets: { section: ['info'] } }]);
            
            console.log(JSON.parse(localStorage.getItem("user")));
        });
        
        


  }
}
